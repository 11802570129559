import React, { Component } from "react"
import Fade from 'react-reveal/Fade'

const times = 1

class LandingProjects extends Component {
  state = {
    current: -1,
    style: {},
  }

  componentDidMount() {
    //window.addEventListener('scroll', this.onScroll)
  }

  componentWillUnmount() {
    //window.removeEventListener('scroll', this.onScroll)
  }

  onScroll = () => {
    let { current } = this.state
    let { items } = this.props
    let viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight)
    // .landing__projects
    let el = document.querySelector('.landing__projects')

    let ncurr = Math.floor(items.length * Math.round(((window.scrollY - el.offsetTop - viewHeight) / (el.clientHeight - viewHeight))*100)/ 100)
    if (ncurr >= items.length) ncurr = items.length - 1
    if (ncurr !== current) this.setState({ current: ncurr })

    let styles = this._styles(((window.scrollY - el.offsetTop - viewHeight) >= el.clientHeight - viewHeight))
    if (styles.position !== this.state.style.position) this.setState({ style: styles })

    let percent = (window.scrollY - el.offsetTop) / (el.clientHeight)
    this.setState({ percent })
  }

  _styles(bool) {
    if (!bool) {
      return {
        position: 'fixed',
      }
    } else {
      return {
        top: (times * 100 * this.props.items.length) - 100 + 'vh',
        position: 'absolute',
      }
    }
  }

  renderImage(image) {
    if (image === null || !image.localFile) return
    return <img src={image.localFile.childImageSharp.original.src} alt={image.alt_text} />
  }

  renderProject = (el, index) => {
    let className = ''
    if (this.state.current > index) {
      className = 'previous'
    } else if (this.state.current < index) {
      className = 'next'
    }

    let props = {
      key: index,
      className: className,
      style: {}, //index === this.props.items.length - 1 ? Object.assign({}, this.state.style) : {}
    }

    /*
    let n = (this.state.percent * this.props.items.length - index)
    n = (Math.round(n * 1000) / 1000)
    props.style.transform = `translateY(${n * -100}vh)`
    */

    return (
      <figure {...props}>
        <Fade bottom distance='40px'>
          <picture>
            { this.renderImage(el.image) }
          </picture>
        </Fade>
        <Fade top distance='40px'>
          <figcaption>
            <h3>{ el.title }</h3>
            <p>{ el.text }</p>
            <span>{ el.tag }</span>
            <div className='line' />
          </figcaption>
        </Fade>
      </figure>
    )
  }

  render() {
    let { items } = this.props

    let i = -1, l = items.length, cols = [[],[]]
    while (++i < l) cols[i % 2].push(items[i])
    return (
      <>
        <section className='landing__projects'>
          <div>
            { cols[0].map(this.renderProject) }
          </div>
          <div className='landing__projects__middle'>
            <p>{ this.props.middle }</p>
          </div>
          <div>
            { cols[1].map(this.renderProject) }
          </div>
        </section>
      </>
    )
  }
}

export default LandingProjects
