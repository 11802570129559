import React, { Component } from 'react'
import { Link } from 'gatsby'

const times = 2

class LandingCta extends Component {
  state = {
    current: 0,
    scroll: false
  }

  componentDidMount() {
    /*
    window.addEventListener('scroll', this.onScroll)
    window.addEventListener('resize', this.onScroll)
    */
  }

  componentWillUnmount() {
    /*
    window.removeEventListener('scroll', this.onScroll)
    window.removeEventListener('resize', this.onScroll)
    */
  }

  onScroll = () => {
    let len = this.props.items.length
    let viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight)
    let el = document.querySelector('.landing__cta')

    let percent = (window.scrollY - el.offsetTop) / (el.clientHeight)

    let current = Math.round(len * percent)
    current = Math.max(current, 0)
    current = Math.min(current, len - 1)
    if (current !== this.state.current ) this.setState({ current })

    let max = ((len * times) - 1) / (len * times)
    percent = Math.max(percent, 0)
    percent = Math.min(percent, max)
    let scroll = (percent > 0 && percent < max)
    if (scroll !== this.state.scroll) this.setState({ scroll })
    let n = percent * viewHeight * times * len
    this.refs.inner.style.transform = `translateY(${n + 'px'})`
  }

  renderImage(image) {
    if (image === null || !image.localFile) return
    return <img src={image.localFile.childImageSharp.original.src} alt={image.alt_text} />
  }

  render() {
    let { current, scroll } = this.state
    let { items } = this.props

    // style={{ height: items.length * times * 100 + 'vh'}}
    // className={`${current === i ? 'active' : ''}`}

    return (
      <section className='landing__cta'>
        <div className='landing__cta__content'>
          <div ref='inner' className={`landing__cta__inner ${scroll && 'active'}`}>
            <div>
              <p className='sidetext'>Which environment would you take to the next level?</p>
              <picture>
                { this.renderImage(items[current].image) }
              </picture>
              <div>
                { items.map((el, i) => <Link to={`/projects/#${el.title}`} className='h3' key={i} onMouseEnter={() => this.setState({ current: i })}>{ el.title }</Link>) }
              </div>
              <p className='bottomtext'>Interested in getting E2 to have a 1hour design meeting with you? let our expert answer any question you have. If this sounds interesting please <Link to='/contact'>Contact Us</Link></p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default LandingCta
