import React, { Component } from 'react'

// slide height (times * viewportHeight)
const times = 1
const colors = ['#2E4539', '#374246', '#4D4442', '#6C646A', '#373832']

class Splash extends Component {

  state = {
    current: 0,
    items: this.props.items, // plug into this.props.data.wordpress.edges
    style: {},
    percent: 0,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll)
    window.addEventListener('resize', this.onScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
    window.removeEventListener('resize', this.onScroll)
  }

  onScroll = () => {
    let { items, current } = this.state
    let viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight)
    let el = document.querySelector('.splash')

    let ncurr = Math.floor(items.length * Math.round((window.scrollY / (el.clientHeight - viewHeight))*100)/ 100)
    if (ncurr >= items.length) ncurr = items.length - 1
    if (ncurr !== current) this.setState({ current: ncurr })

    let styles = this._styles((window.scrollY >= el.clientHeight - viewHeight))
    if (styles.position !== this.state.style.position) this.setState({ style: styles })

    let percent = (window.scrollY / (el.clientHeight))
    this.setState({ percent })

    //console.log(Math.floor(percent * items.length))
  }

  _styles(bool) {
    if (!bool) {
      return {
        top: 0,
        position: 'fixed',
      }
    } else {
      return {
        top: (times * 100 * this.state.items.length) - 100 + 'vh',
        position: 'absolute',
      }
    }
  }

  renderImage(image) {
    if (image === null || !image.localFile) return
    return <img src={image.localFile.childImageSharp.original.src} alt={image.alt_text} />
  }

  renderItem(el, index) {
    let className = ''
    if (this.state.current > index) {
      className = 'previous'
    } else if (this.state.current < index) {
      className = 'next'
    }

    let props = {
      key: index,
      className: `splash__item ${className ? 'splash__item--'+className : ''}`,
      style: {},
    }

    let n = (this.state.percent * this.state.items.length - index)
    n = (Math.round(n * 1000) / 1000)
    props.style.transform = `translateY(${n * -100}vh)`
    //props.style.opacity = n * -1

    return (
      <div {...props}>
        <div className='splash__item__inner'>
          <h2>{ el.title }</h2>
          <p>{ el.tag }</p>
        </div>
        <picture>
          { this.renderImage(el.image) }
        </picture>
      </div>
    )
  }

  _renderDot(el, i) {
    let props = {
      key: i,
      className: i === this.state.current ? 'active' : '',
      onClick: () => {
        let viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight)
        window.scroll({
          top: viewHeight * (i * times),
          left: 0,
          behavior: 'smooth'
        })
      },
    }
    return (
      <svg {...props} width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(1.000000, 1.000000)">
            <circle className='ring' stroke="#FFFFFF" cx="10" cy="10" r="10"></circle>
            <circle className='dot' fill="#FFFFFF" transform="translate(10.000000, 10.000000) rotate(-270.000000) translate(-10.000000, -10.000000) " cx="10" cy="10" r="5"></circle>
          </g>
        </g>
      </svg>
    )
  }

  render() {
    let { items, current } = this.state

    let style = Object.assign({}, this.state.style)
    style.backgroundColor = colors[current]

    return (
      <>
        <div className='splash' style={{ height: this.state.items.length * (100 * times) + 'vh' }}>
          <div className='splash__hider' style={style} />
          <div className='splash__background' style={style} />
          <div className='splash__dots' style={this.state.style}>
            <div className='splash__dots__wrap'>
              { items.map(this._renderDot.bind(this)) }
            </div>

          </div>
          { items.map(this.renderItem.bind(this)) }
        </div>
      </>
    )
  }
}

export default Splash
