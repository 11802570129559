import React, { Component } from "react"

class Progress extends Component {
  componentDidMount() {
    window.addEventListener('scroll', this.onScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  }

  onScroll = () => {
    let circle = document.getElementById('circle')
    let angle = Math.round((window.scrollY + window.innerHeight) / document.body.clientHeight * 210)
    circle.setAttribute("stroke-dasharray", angle + ", 20000")
  }

  render() {
    return (
      <div className='landing__progress'>
        <svg width="70px" height="70px" viewBox="0 0 70 70" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g stroke="#96998B" strokeWidth="3" fill="none" fillRule="evenodd">
            <circle id='circle' cx="35" cy="35" r="33.5" strokeDasharray="0,20000" transform="rotate(-90,35,35)" />
          </g>
        </svg>
      </div>
    )
  }
}

export default Progress
