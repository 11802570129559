import React, { Component } from "react"
import { graphql } from 'gatsby'
import Fade from 'react-reveal/Fade'
import he from 'he'

import SEO from "../components/seo"
import Splash from '../components/splash'
import Progress from '../components/progress'
import LandingProjects from '../components/landing-projects'
import LandingCta from '../components/landing-cta'

class IndexPage extends Component {

  renderImage(image) {
    if (image === null || !image.localFile) return
    return <img src={image.localFile.childImageSharp.original.src} alt={image.alt_text} />
  }

  render() {
    let data = this.props.data.page.acf
    let page = this.props.data.page
    return (
      <>
        <SEO title={he.decode(page.yoast_meta.yoast_wpseo_title)} bodyClass="landing" description={page.yoast_meta.yoast_wpseo_metadesc} />
        <Splash items={data.splash} />
        <Progress />

        <section className='landing__blurb'>
          <Fade bottom distance='40px'>
            <div className='landing__blurb__intro' dangerouslySetInnerHTML={{__html: data.intro }} />
          </Fade>
          <Fade bottom distance='40px'>
            <figure>
              <picture>
                { this.renderImage(data.color_block.image) }
              </picture>
              <figcaption>
                { data.color_block.text.split('\n').map((t, i) => t.length > 1 && <p key={i}>{ t }</p>) }
              </figcaption>
            </figure>
          </Fade>
        </section>

        <LandingProjects items={data.projects} middle={data.projects_middle_text} />

        <LandingCta items={data.triple_section} />

      </>
    )
  }
}

export default IndexPage

export const query = graphql`
  query {
    page: wordpressPage(wordpress_id: {eq: 2}) {
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        splash {
          title
          tag
          image {
            ...original
          }
        }
        intro
        color_block {
          text
          image {
            ...original
          }
        }
        projects_middle_text
        projects {
          title
          text
          tag
          image {
            ...original
          }
        }
        triple_section {
          title
          image {
            ...original
          }
        }
      }
    }
  }
`
